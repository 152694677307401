import {useContext, useState} from 'react';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {
  StoreProjectRequest,
  useStoreProjectMutation,
} from 'features/studio/projects/store/store-project';
import {ProjectTypeEnum} from 'features/studio/projects/constants/project-type';
import {useEnumAsOption} from 'features/studio/projects/utils/use-enum-as-option';
import {ProjectStatusEnum} from 'features/studio/projects/constants/project-status';
import {differenceInCalendarMonths, parse} from 'date-fns';
import {useFilterTypesByPermission} from 'features/studio/projects/utils/use-filter-type-by-permission';
import {Option} from 'types/option';

export const useProjectForm = () => {
  const [storeProject, {isLoading, error}] = useStoreProjectMutation();

  const serverError = parseValidationErrors(error);

  const [params, setParams] = useState<Params>({
    name: {
      fr: '',
      en: '',
    },
    description: {
      fr: '',
      en: '',
    },
  } as Params);

  const navigate = useNavigate();

  const typeOptions = useFilterTypesByPermission(
    useEnumAsOption(ProjectTypeEnum, 'createProjectPage')
  );
  const statusOptions = useEnumAsOption(ProjectStatusEnum, 'statusBadge');

  const handleChange = (key: keyof Params, value: any) => {
    setParams(prev => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleSubmit = async () => {
    delete params.duration_in_months;

    const payload = {
      ...params,
      project_type: params.project_type?.id,
      status: params.status?.id,
    } as StoreProjectRequest;

    const result = await storeProject(payload);

    if ('data' in result) {
      const project = result?.data.data;
      navigate(`/dashboard/projects/${project.id}/general`);
    }
  };

  const handleDating = (
    key: keyof Pick<Params, 'starts_at' | 'duration_in_months' | 'ends_at'>,
    value: string
  ) => {
    if (key === 'duration_in_months') {
      if (!params.starts_at) return;

      const starts_at = new Date(params.starts_at as string);
      const ends_at = new Date(
        starts_at.setMonth(starts_at.getMonth() + Number(value))
      ).toDateString();

      setParams({...params, ends_at, [key]: value});
    } else if (key === 'ends_at') {
      if (!params.starts_at) return;

      const starts_at_date = parse(
        new Date(params.starts_at).toLocaleDateString(),
        'dd/MM/yyyy',
        new Date()
      );
      const ends_at_date = parse(
        new Date(value as string).toLocaleDateString(),
        'dd/MM/yyyy',
        new Date()
      );

      const duration = differenceInCalendarMonths(ends_at_date, starts_at_date);

      setParams({
        ...params,
        duration_in_months: duration.toString(),
        [key]: value.toString(),
      });
    } else {
      setParams({...params, [key]: value.toString()});
    }
  };

  return {
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    options: {
      types: typeOptions,
      status: statusOptions,
    },
    setParams,
    submit: handleSubmit,
    handleDating,
    handleChange,
  };
};

type Params = Omit<StoreProjectRequest, 'project_type' | 'status'> & {
  duration_in_months?: string;
  project_type: Option;
  status: Option;
};
