import React, {FC} from 'react';
import messages from '../messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {MODAL} from 'features/modal-notch/constants';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Button, CardBody, Col, Row} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';

const CustomerOrderControls = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const addColor = () => {
    dispatch(
      showModal(MODAL.COLOR_WEB_MODAL, {
        store: true,
      })
    );
  };
  return (
    <Row>
      <Col>
        <CardBody>
          <Row className="mb-3">
            <Col md={6} className="justify-content-start d-flex"></Col>
            <Col md={2} />

            <Col className="justify-content-end d-flex" md={4}>
              <AppBarActions>
                <Button className="btn btn-important" onClick={addColor}>
                  <Icon name="plus" className="me-1" size={15} />
                  {t(...messages.addColor())}
                </Button>
              </AppBarActions>
            </Col>
          </Row>
        </CardBody>
      </Col>
    </Row>
  );
};

export default CustomerOrderControls;
