import React from 'react';
import {AppRoute} from './types';
import ProductsPage from 'pages/dashboard/products';
import CreateProductPage from 'pages/dashboard/products/create';
import EditProductPage from 'pages/dashboard/products/edit';
import FamiliesPage from 'pages/dashboard/products/family';
import MasterProductsPage from 'pages/dashboard/products/master-product';
import MasterProductsWebPage from 'pages/dashboard/products/master-product-web';
import CreateStockOrderPage from 'pages/dashboard/stock-orders/create/create-stock-order-page';
import StockOrderPage from 'pages/dashboard/stock-orders/stock-orders-page';
import CreateSupplierOrdersPage from 'pages/dashboard/supplier-orders/create/create-supplier-order-page';
import SupplierOrdersPage from 'pages/dashboard/supplier-orders/supplier-orders-page';
import CreateProductWebPage from 'pages/dashboard/products/master-product-web/create';
import EditProductWebPage from 'pages/dashboard/products/master-product-web/edit';
import FamiliesWebPage from 'pages/dashboard/products/family-web';

const productionRoutes: Array<AppRoute> = [
  {
    path: '/dashboard/products/:id/*',
    component: <EditProductPage />,
    subject: 'products',
  },
  {
    path: '/dashboard/products/:id/variants/:variant/*',
    component: <EditProductPage />,
    subject: 'products',
  },
  {
    path: '/dashboard/products/:id/edit/*',
    component: <EditProductPage />,
    subject: 'products',
  },
  {
    path: '/dashboard/products',
    component: <ProductsPage />,
    subject: 'products',
  },
  {
    path: '/dashboard/products/create',
    component: <CreateProductPage />,
    subject: 'products',
  },
  {
    path: '/dashboard/families',
    component: <FamiliesPage />,
    subject: 'products',
  },
  {
    path: '/dashboard/families/web',
    component: <FamiliesWebPage />,
    subject: 'products',
  },
  {
    path: '/dashboard/supplier-orders',
    component: <SupplierOrdersPage />,
    subject: ['cfv', 'cfz'],
  },
  {
    path: 'dashboard/master-products',
    component: <MasterProductsPage />,
    subject: 'products',
  },
  {
    path: 'dashboard/master-products-web',
    component: <MasterProductsWebPage />,
    subject: 'products',
  },
  {
    path: 'dashboard/master-products-web/create',
    component: <CreateProductWebPage />,
    subject: 'products',
  },
  {
    path: 'dashboard/master-products-web/:id/edit',
    component: <EditProductWebPage />,
    subject: 'products',
  },
  {
    path: '/dashboard/supplier-orders/create',
    component: <CreateSupplierOrdersPage />,
    subject: ['cfv', 'cfz'],
    action: ['create-and-edit'],
  },
  {
    path: '/dashboard/stock-orders',
    component: <StockOrderPage />,
    subject: ['ccs'],
  },
  {
    path: '/dashboard/stock-orders/create',
    component: <CreateStockOrderPage />,
    subject: ['ccs'],
    action: ['create-and-edit'],
  },
];

export default productionRoutes;
