import React from 'react';
import EmptyCell from 'components/empty-cell';
import FormattedDate from 'components/formatted-date';
import {Trans} from 'react-i18next';
import { ProductWebFamilyIndex } from 'types/product-family';
import { ColumnDef } from '@tanstack/react-table';
import FamilyWebActions from './family-web-actions';

const columns: ColumnDef<ProductWebFamilyIndex>[] = [
  {
    header: r => <Trans i18nKey="familyWebPage.name" />,
    accessorFn: row => <EmptyCell cell={row.name} />,
    cell: r => r.getValue(),
    id: 'name',
    enableSorting: true,
    meta: {costumClass: 'font-size-14'},
  },
  {
    header: r => <Trans i18nKey="familyWebPage.createdAt" />,
     enableSorting: false,
     cell: r => r.getValue(),
    id: 'created_at',
    accessorFn: row => <FormattedDate value={row.created_at} format="date" />,
    meta: {costumClass: 'font-size-14'},
  },
  {
    header: r => <Trans i18nKey="productsPage.action" />,
     enableSorting: false,
    accessorFn: row => <FamilyWebActions family_web={row} />,
    cell: r => r.getValue(),
    id: 'actions',
    meta: {costumClass: 'font-size-14'},
  },
];

export default columns;
