import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  color: () => __(translations.colorsPage.color, 'Color'),
  title: () => __(translations.colorsPage.title, 'Colors'),
  name: () => __(translations.colorsPage.name, 'Name'),
  update: () => __(translations.colorsPage.update, 'Update'),
  code: () => __(translations.colorsPage.code, 'Code'),
  addColor: () => __(translations.colorsPage.addColor, 'Add Color'),
  colorWeb: () =>  __(translations.colorsPage.colorWeb, 'Colors Web'),
  nameFr: () => __(translations.colorsPage.nameFr, 'Name in French'),
  nameEn: () => __(translations.colorsPage.nameEn, 'Name in English'),
  colorCode: () => __(translations.colorsPage.colorCode, 'Color code'),
  yesImSure: () => __(translations.colorsPage.yesImSure, 'Yes im sure'),
  cancel: () => __(translations.colorsPage.cancel, 'Cancel'),
  colorCreatedSuccess: () => __(translations.colorsPage.colorCreatedSuccess, 'Color created successfully'),
  colorArchivedSuccess: () => __(translations.colorsPage.colorArchivedSuccess, 'Color deleted successfully'),
  colorUpdatedSuccess: () => __(translations.colorsPage.colorUpdatedSuccess, 'Color updated successfully'),
  deleteAlertMessage: () => __(translations.colorsPage.deleteAlertMessage, 'Are you sure you want to delete this color?'),
  actionOnColors: () => __(translations.colorsPage.actionOnColors, 'Action on colors'),
};
