import {api} from 'services/api';
import {Color} from 'types/colors';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const archiveWebColor = api.injectEndpoints({
  endpoints: builder => ({
    archiveWebColor: builder.mutation<Response, ArchiveColorRequest>({
      query: params => {
        return {
          method: 'DELETE',
          url: `color-webs/${params.id}`,
        };
      },
    }),
  }),
});

export type ArchiveColorRequest = {
  id: ObjectId;
};

type Response = ResponseData<Color>;

export const {useArchiveWebColorMutation} = archiveWebColor;
