import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {useFamilyWebForm} from './use-family-web-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {LocalizedTextInput} from 'components/text-input/multi';
import {LANGUAGES} from 'constants/language';

const FamilyWebUpdateForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {submit, params, isLoading, validationErrors, errorMessage, setParams} =
    useFamilyWebForm();

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <LocalizedTextInput
            languages={LANGUAGES}
            label={lang =>
              t(...messages.name(), {
                lang: lang,
              })
            }
            value={lang => params.name[lang]}
            onChange={(value, lang) => {
              setParams(prevState => ({
                ...prevState,
                name: {
                  ...prevState.name,
                  [lang]: value,
                },
              }));
            }}
            errors={[
              validationErrors[`name.fr`],
              validationErrors[`name.en`],
            ].flat()}
          />
          <div className="row mb-4">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default FamilyWebUpdateForm;
