import React, {FC} from 'react';
import messages from './messages';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';

import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import {Check, Plus} from 'react-feather';
import Icon from '@ailibs/feather-react-ts';
import {useCreateProductWeb} from './use-create-product-web';
import Button from 'components/button';
import {LocalizedTextInput} from 'components/text-input/multi';
import {LANGUAGES} from 'constants/language';
import {LocalizedTextEditor} from 'components/text-editor/multi-lang';

interface Props {}

const CreateProductWebPage: FC<Props> = () => {
  const {t} = useTranslation();

  const {
    params,
    validationErrors,
    isLoading,
    setParams,
    handleAddSize,
    handleChangeSize,
    handleRemoveSize,
    handleSubmit,
  } = useCreateProductWeb();

  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t(...messages.products()), path: '/dashboard/products'},
            {
              title: t(...messages.masterProductsWeb()),
              path: '/dashboard/master-products-web',
            },
            {title: t(...messages.create()), path: '#'},
          ]}
        />

        <Card>
          <CardBody>
            <Row className="mb-5">
              <LocalizedTextInput
                languages={LANGUAGES}
                label={lang =>
                  t(...messages.name(), {
                    lang: lang,
                  })
                }
                value={lang => params[`name_${lang}`]}
                onChange={(value, lang) => {
                  setParams({
                    ...params,
                    [`name_${lang}`]: value,
                  });
                }}
                errors={[
                  validationErrors[`name_en`],
                  validationErrors[`name_fr`],
                ].flat()}
              />
            </Row>
            <Row className="mb-5">
              <LocalizedTextEditor
                id={lang => `reassurance_information__editor_${lang}`}
                languages={LANGUAGES}
                label={lang =>
                  t(...messages.reassuranceInformations(), {
                    lang: lang,
                  })
                }
                value={lang => params[`reassurance_information_${lang}`]}
                onChange={(value, lang) => {
                  setParams({
                    ...params,
                    [`reassurance_information_${lang}`]: value,
                  });
                }}
                errors={[
                  validationErrors[`reassurance_information_fr`],
                  validationErrors[`reassurance_information_en`],
                ].flat()}
              />
            </Row>
            <Row className="mb-5">
              <LocalizedTextEditor
                id={lang => `skill_editor_${lang}`}
                languages={LANGUAGES}
                label={lang =>
                  t(...messages.knowledge(), {
                    lang: lang,
                  })
                }
                value={lang => params[`skill_${lang}`]}
                onChange={(value, lang) => {
                  setParams({
                    ...params,
                    [`skill_${lang}`]: value,
                  });
                }}
                errors={[
                  validationErrors[`skill_fr`],
                  validationErrors[`skill_en`],
                ].flat()}
              />
            </Row>
            <Button color="important" className="mb-5" onClick={handleAddSize}>
              <Plus size={18} /> {t('createProductWebPage.addSize')}
            </Button>

            <div className="invalid-feedback d-block">
              {Array.isArray(validationErrors['sizes'])
                ? validationErrors['sizes'][0]
                : validationErrors['sizes']}
            </div>

            {params.sizes.map(({id, value}, index) => (
              <Row
                key={id}
                className="mb-3 justify-content-center align-items-center"
              >
                <Col>
                  <LocalizedTextInput
                    languages={LANGUAGES}
                    label={lang =>
                      t(...messages.name(), {
                        lang: lang,
                      })
                    }
                    value={lang => value[lang]}
                    onChange={(value, lang) =>
                      handleChangeSize(id, value, lang)
                    }
                    errors={[
                      validationErrors[`sizes.${index}.fr`],
                      validationErrors[`sizes.${index}.en`],
                    ].flat()}
                  />
                </Col>
                <Col>
                  <Button
                    color="link"
                    className="d-flex align-items-center"
                    onClick={() => handleRemoveSize(id)}
                  >
                    <Icon name="trash-2" size={20} />
                  </Button>
                </Col>
              </Row>
            ))}

            <AppBarActions>
              <Button
                isLoading={isLoading}
                color="important"
                onClick={handleSubmit}
              >
                <Check size={15} /> {t('common.saveChanges')}
              </Button>
            </AppBarActions>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default CreateProductWebPage;
