import {useState} from 'react';
import messages from '../messages';
import {
  UpdateColorRequest,
  useUpdateWebColorMutation,
} from '../store/endpoints/update-color';
import {useListSlice} from 'features/table/slice';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {Color} from 'types/colors';

import {StoreColorRequest} from '../store/endpoints/store-color';

const RESOURCE_NAME = 'color-webs';
const FETCH_URL = RESOURCE_NAME;

export const useUpdateColorForm = (onDismiss: () => void, color?: Color) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {actions} = useListSlice();

  const [updateColor, {isLoading, error}] = useUpdateWebColorMutation();
  const serverError = parseValidationErrors(error);

  const [params, setParams] = useState<Params>({
    name: {
      en: color?.translation?.en ?? '',
      fr: color?.translation?.fr ?? '',
    },
    code: color?.code,
  });

  const handleSubmit = async () => {
    const result = await updateColor({
      ...params,
      id: color?.id,
    });

    if ('data' in result) {
      dispatch(toast('success', t(...messages.colorUpdatedSuccess())));

      dispatch(actions.clearSelection(RESOURCE_NAME));
      dispatch(actions.loadData({url: FETCH_URL, name: RESOURCE_NAME}));

      onDismiss();
    }
  };

  return {
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    setParams,
    submit: handleSubmit,
  };
};
type Params = StoreColorRequest;
