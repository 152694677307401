import {useState} from 'react';
import loadData from 'features/table/slice/actions/load-data';
import {useDispatch} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useStoreFamilyWebMutation} from 'store/production/endpoints/store-family-web';
import {uiActions} from 'store/ui/ui-slice';
import {useSeeder} from 'utils/use-seeder';
import toast from 'store/ui/actions/toast';
import messages from './messages';
import { useTranslation } from 'react-i18next';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useFamilyWebForm = () => {
  const [storeFamily, {isLoading, error}] = useStoreFamilyWebMutation();
  const {t} = useTranslation();
  const serverError = parseValidationErrors(error);

  const fake = useSeeder();

  const [params, setParams] = useState({
    name: {
      fr: __DEV__ ? fake.productName : '',
      en: __DEV__ ? fake.productName : ''
    } 
  });

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await storeFamily(params);
    if ('data' in result) {
      dispatch(toast('success', t(...messages.createdToast())));
      dispatch(loadData({name: 'families-web', page: 1, url: 'product-family-webs'}));

      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
