import {Language} from 'components/text-editor/multi-lang/types';
import {uniqueId} from 'lodash';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import {ValidationErrors} from 'services/api/types';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useShowProductWebQuery} from 'store/production/endpoints/show-product-web';
import {
  UpdateProductWebRequest,
  useUpdateProductWebMutation,
} from 'store/production/endpoints/update-product-web';
import toast from 'store/ui/actions/toast';
import {ObjectId} from 'types/object-id';
import makeId from 'utils/make-id';

type Params = {
  name_fr: string;
  name_en: string;
  sizes: {
    id: ObjectId;
    value: {
      fr: string;
      en: string;
    };
  }[];
  deleted_records: ObjectId[];
};

export interface IProductWebForm {
  params: Params;
  validationErrors: ValidationErrors;
  isLoading: boolean;
  skill_fr: string;
  skill_en: string;
  reassurance_information_fr: string;
  reassurance_information_en: string;
  set_reassurance_information_fr: (arg: string) => void;
  set_reassurance_information_en: (arg: string) => void;
  set_skill_fr: (arg: string) => void;
  set_skill_en: (arg: string) => void;
  setParams: (arg: Params) => void;
  handleAddSize: () => void;
  handleChangeSize: (id: ObjectId, value: string, key: 'fr' | 'en') => void;
  handleRemoveSize: (id: ObjectId) => void;
  handleSubmit: () => void;
}

export const useEditProductWeb = (): IProductWebForm => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [updateProductWeb, {isLoading, error}] = useUpdateProductWebMutation();
  const serverError = parseValidationErrors(error);

  const {id} = useParams();
  const {data} = useShowProductWebQuery({id});
  const productWeb = data?.data;
  const [params, setParams] = useState<Params>({
    name_fr: '',
    name_en: '',
    sizes: [],
    deleted_records: [],
  });

  const [reassurance_information_fr, set_reassurance_information_fr] =
    useState('');
  const [reassurance_information_en, set_reassurance_information_en] =
    useState('');
  const [skill_fr, set_skill_fr] = useState('');
  const [skill_en, set_skill_en] = useState('');

  useEffect(() => {
    if (!productWeb) return;

    setParams({
      ...params,
      name_fr: productWeb?.name_translations.fr ?? '',
      name_en: productWeb?.name_translations.en ?? '',
      sizes:
        productWeb?.size_webs.map(el => ({
          id: el.id,
          value: {
            en: el.name_translations?.en,
            fr: el.name_translations?.fr,
          },
        })) ?? [],
    });

    set_skill_fr(productWeb.skill?.fr);
    set_skill_en(productWeb.skill?.en);
    set_reassurance_information_fr(productWeb.reassurance_information?.fr);
    set_reassurance_information_en(productWeb.reassurance_information?.en);
  }, [productWeb]);

  const handleSubmit = async () => {
    const payload: UpdateProductWebRequest = {
      id,
      name_fr: params.name_fr,
      name_en: params.name_en,
      skill_fr: skill_fr,
      skill_en: skill_en,
      reassurance_information_fr: reassurance_information_fr,
      reassurance_information_en: reassurance_information_en,
      deleted_records: params.deleted_records,
    };

    if (params.sizes.some(el => el.value)) payload.sizes = params.sizes;

    const result = await updateProductWeb(payload);

    if ('data' in result) {
      dispatch(toast('success', t('updateProductWebPage.productWebUpdated')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const handleAddSize = () => {
    setParams({
      ...params,
      sizes: [
        ...params.sizes,
        {
          id: makeId(),
          value: {
            fr: '',
            en: '',
          },
        },
      ],
    });
  };

  const handleChangeSize = (id: ObjectId, value: string, key: Language) => {
    const updatedSize = [...params.sizes];

    const target = updatedSize.findIndex(el => id === el.id);
    if (target === -1) return;

    updatedSize[target] = {
      ...updatedSize[target],
      value: {
        ...updatedSize[target].value,
        [key]: value,
      },
    };

    setParams({...params, sizes: updatedSize});
  };

  const handleRemoveSize = (id: ObjectId) => {
    const updatedSize = [...params.sizes].filter(el => el.id != id);

    setParams(prev => ({
      ...prev,
      sizes: updatedSize,
    }));

    if (typeof id === 'number') {
      setParams(prev => ({
        ...prev,
        deleted_records: [...params.deleted_records, id],
      }));
    }
  };

  return {
    params,
    skill_fr,
    skill_en,
    reassurance_information_en,
    reassurance_information_fr,
    set_reassurance_information_fr,
    set_reassurance_information_en,
    set_skill_fr,
    set_skill_en,
    validationErrors: serverError.errors,
    isLoading,
    setParams,
    handleAddSize,
    handleChangeSize,
    handleRemoveSize,
    handleSubmit,
  };
};
