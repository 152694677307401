import React from 'react';
import {FC} from 'react';
import Button from 'components/button';
import Can from 'features/hylian-shield/can';
import {tableSlice} from 'features/table/slice';
import {Trash2} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {Project, ProjectIndex} from 'types/project';
import {uiActions} from 'store/ui/ui-slice';
import {ProjectTypeEnum} from 'features/studio/projects/constants/project-type';
import {Subject} from 'features/hylian-shield/types';

interface Props {
  project: ProjectIndex;
}

const ProjectActions: FC<Props> = ({project}) => {
  const dispatch = useDispatch();

  const {t} = useTranslation();

  const subject: Subject =
    project.project_type === ProjectTypeEnum.PETITS_PROJETS
      ? 'studio-petits-projects'
      : 'studio-projects';

  const destroyProject = () => {
    dispatch(
      tableSlice.actions.destroyResource({
        resourceId: project.id,
        resourceName: 'projects',
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      uiActions.confirmDelete({
        resourceId: project.id,
        name: 'projects',
        onDestroy: destroyProject,
      })
    );
  };

  return (
    <div className="d-flex align-items-center h-100">
      <Can action="administration" subject={subject}>
        <Button color="transparent" onClick={handleDelete}>
          <Trash2 size={20} className="text-danger" />
        </Button>
      </Can>
    </div>
  );
};

export default ProjectActions;
