import React, {FC} from 'react';
import {Fragment} from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import {MODAL} from 'features/modal-notch/constants';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {CardBody, Row, Col, FormGroup} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import Can from 'features/hylian-shield/can';

const FamilyWebFilters: FC = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleUpdateFamilyWeb = () => {
    dispatch(showModal(MODAL.FAMILY_WEB_FORM));
  };

  return (
    <Fragment>
      <CardBody className="py-0">
        {/* First row */}
        <Row className="align-items-center">
          <Col md={3}>
            <AppBarActions>
              <Can action="administration" subject="products">
                <div
                  className="btn btn-important "
                  onClick={() => handleUpdateFamilyWeb()}
                >
                  <Icon name="plus" className="me-1" size={15} />
                  {t(...messages.addFamily())}
                </div>
              </Can>
            </AppBarActions>
          </Col>
        </Row>
        {/* <!-- end row --> */}
        {/* First row */}
      </CardBody>
    </Fragment>
  );
};

export default FamilyWebFilters;
