import React from 'react';
import {FC, useMemo} from 'react';
import {FormFeedback, Input, Label} from 'reactstrap';
import useInputId from 'utils/use-input-id';
import {TextInputProps} from './types';

const TextInput: FC<TextInputProps> = props => {
  const {
    value,
    errors,
    onChange,
    label,
    required,
    type,
    inputStyle,
    wrapperStyle,
    placeholder,
    labelStyle,
    disabled,
    id: inputId,
    containerStyle,

    errorMessageStyle,
    ...rest
  } = props;

  const errorMessage = useMemo(() => {
    return Array.isArray(errors) ? errors.find(notNull => notNull) : errors;
  }, [errors]);

  const id = useInputId(inputId);

  return (
    <div className={containerStyle}>
      <span className="text-danger">{required && '* '}</span>
      {label && (
        <Label className="mb-0" htmlFor={id}>
          {label}
        </Label>
      )}
      <Input
        id={id}
        className={`form-control  ${disabled ? 'bg-gray-light' : ''}`}
        style={inputStyle}
        disabled={disabled}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        value={value ?? ''}
        type={type}
        invalid={errorMessage ? true : false}
        {...rest}
      />
      {errorMessage ? (
        <FormFeedback type="invalid">{errorMessage}</FormFeedback>
      ) : null}
    </div>
  );
};

export default TextInput;
