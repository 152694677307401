import React from 'react';
import {FC} from 'react';
import Can from 'features/hylian-shield/can';
import {MODAL} from 'features/modal-notch/constants';
import {Edit, FileText, Trash2} from 'react-feather';
import {Trans} from 'react-i18next';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import {Material} from 'types/materials';
import Button from 'components/button';

interface Props {
  material: Material;
}

const MaterialActions: FC<Props> = ({material}) => {
  const dispatch = useDispatch();

  const handleUpdate = () => {
    dispatch(
      showModal(MODAL.MATERIAL_MODAL, {store: false, material: material})
    );
  };

  const handleArchive = () => {
    dispatch(
      showModal(MODAL.MATERIAL_ARCHIVE_MODAL, {
        materialId: material.id,
      })
    );
  };
  return (
    <div className="d-flex">
      <Can action="edit" subject="settings-matter">
        <Button color="transparent" onClick={handleUpdate} className="me-2">
          <Edit size={20} />
        </Button>

        <Button color="transparent" onClick={handleArchive}>
          <Trash2 className="text-danger" size={20} />
        </Button>
      </Can>
    </div>
  );
};

export default MaterialActions;
