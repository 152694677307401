import {Container, LanguageSelector, SelectorWrapper} from './styled';
import React, {FC, useState} from 'react';
import {ILocalizedTextInput} from './types';
import TextInput from '..';
import i18next from 'i18next';
import {Row} from 'reactstrap';

export const LocalizedTextInput: FC<ILocalizedTextInput> = ({
  value,
  label,
  errors,
  onChange,
  languages = i18next.languages,
  required,
  ...rest
}) => {
  const [currentLanguage, setCurrentLanguage] = useState<any>(
    languages?.length > 0 ? languages[0] : i18next.language
  );

  const handleChange = (newValue: string) => {
    onChange(newValue, currentLanguage);
  };

  return (
    <Row>
      <Container>
        {/* Language Selector */}
        <SelectorWrapper>
          <LanguageSelector
            name="languages"
            id="languages"
            value={currentLanguage}
            onChange={e => setCurrentLanguage(e.target.value)}
          >
            {languages.map(lang => (
              <option key={lang} value={lang}>
                {lang.toUpperCase()}
              </option>
            ))}
          </LanguageSelector>
        </SelectorWrapper>

        {/* Input / Editor */}
        <TextInput
          value={value(currentLanguage)}
          label={label(currentLanguage)}
          errors={errors}
          onChange={handleChange}
          {...rest}
        />
      </Container>
    </Row>
  );
};
