import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  color: () => __(translations.colorsWebPage.color, 'Color web'),
  title: () => __(translations.colorsWebPage.title, 'Colors'),
  name: () => __(translations.colorsWebPage.name, 'Name'),
  update: () => __(translations.colorsWebPage.update, 'Update'),
  code: () => __(translations.colorsWebPage.code, 'Code'),
  addColor: () => __(translations.colorsWebPage.addColor, 'Add Color web'),
  nameFr: () => __(translations.colorsWebPage.nameFr, 'Name in French'),
  nameEn: () => __(translations.colorsWebPage.nameEn, 'Name in English'),
  colorCode: () => __(translations.colorsWebPage.colorCode, 'Color web code'),
  yesImSure: () => __(translations.colorsWebPage.yesImSure, 'Yes im sure'),
  cancel: () => __(translations.colorsWebPage.cancel, 'Cancel'),
  colorCreatedSuccess: () => __(translations.colorsWebPage.colorCreatedSuccess, 'Color web created successfully'),
  colorArchivedSuccess: () => __(translations.colorsWebPage.colorArchivedSuccess, 'Color web deleted successfully'),
  colorUpdatedSuccess: () => __(translations.colorsWebPage.colorUpdatedSuccess, 'Color web updated successfully'),
  deleteAlertMessage: () => __(translations.colorsWebPage.deleteAlertMessage, 'Are you sure you want to delete this color web?'),
  actionOnColors: () => __(translations.colorsWebPage.actionOnColors, 'Action on colors web'),
};
