import {useState} from 'react';
import messages from '../messages';
import {
  StoreColorRequest,
  useStoreWebColorMutation,
} from '../store/endpoints/store-color';
import {useListSlice} from 'features/table/slice';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';

const RESOURCE_NAME = 'color-webs';
const FETCH_URL = RESOURCE_NAME;

export const useColorForm = (onDismiss: () => void) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {actions} = useListSlice();

  const [params, setParams] = useState<Params>({
    name: {
      en: '',
      fr: '',
    },
    code: '',
  });

  const [storeColor, {isLoading, error}] = useStoreWebColorMutation();

  const serverError = parseValidationErrors(error);

  const handleSubmit = async () => {
    const result = await storeColor(params);
    if ('data' in result) {
      dispatch(toast('success', t(...messages.colorCreatedSuccess())));

      dispatch(actions.clearSelection(RESOURCE_NAME));
      dispatch(actions.loadData({url: FETCH_URL, name: RESOURCE_NAME}));

      onDismiss();
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
type Params = StoreColorRequest;
