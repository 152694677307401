import React, {FC, useCallback, useContext, useMemo} from 'react';
import documentContext from '../../containers/document-context/document-context';
import updateItem from '../../store/actions/update-item';
import selectRelatableItems from '../../store/selectors/select-relatable-items';
import {CommercialProduct} from '../../types/commercial-product';
import messages from './messages';
import ItemPickerMultiple from 'components/item-picker-multiple/item-picker-multiple';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';

interface Props {
  item: CommercialProduct;
  index: number;
}

const LinkedItemsPicker: FC<Props> = ({item, index}) => {
  const relatableProducts = useSelector(selectRelatableItems(item));
  const linkedProducts = item?.linked_products;

  const {validationErrors, is_locked} = useContext(documentContext);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const sortedRelatableProducts = useMemo(() =>
      relatableProducts.sort((x, i) => (x?.order ?? 0) - (i?.order ?? 0)),
    [relatableProducts]
  );

  const sortedLinkedProducts = useMemo(() =>
      (linkedProducts || []).toSorted((x, i) => (x?.order ?? 0) - (i?.order ?? 0)),
    [linkedProducts]
  );

  const handleUpdate = useCallback((key: keyof CommercialProduct, v: any) => {
    dispatch(updateItem(item, key, v));
  }, [dispatch, item]);

  return (
    <div>
      <ItemPickerMultiple
        options={sortedRelatableProducts}
        label={t(...messages.chooseProductToLink())}
        errors={validationErrors[`products.${index}.linked_products`]}
        isDisabled={is_locked}
        isMulti
        value={sortedLinkedProducts}
        getOptionLabel={(x: any) => + x.order + ' -- '  + x.name }
        getOptionValue={(x: any) => x?.id}
        onChange={(p: any) => handleUpdate('linked_products', p)}
      />
      <div className="invalid-feedback d-block">
        {item?.linked_products?.map((x, i) => (
          <div key={x.id}>
            {validationErrors[`products.${index}.child_ids.${i}`]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LinkedItemsPicker;
