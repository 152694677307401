import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ProductFamily} from 'types/product-family';
import {ResponseData} from 'types/response-data';

const storeFamilyWeb = api.injectEndpoints({
  endpoints: builder => ({
    storeFamilyWeb: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'POST',
          url: `product-family-webs`,
          body: {
            name: {
              fr: params.name.fr,
              en: params.name.en
            }
          },
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
  name: {
    fr: string,
    en: string
  }
};

type Response = ResponseData<ProductFamily>;

export const {useStoreFamilyWebMutation} = storeFamilyWeb;

export const storeFamilyWebEndpoint = storeFamilyWeb.endpoints.storeFamilyWeb;
