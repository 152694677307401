import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  general: () => __(translations.editProjectGeneralSection.general, 'General'),
  projectName: () => __(translations.editProjectGeneralSection.projectName, 'Project name ({{lang}})'),
  privateProjectName: () => __(translations.editProjectGeneralSection.privateProjectName, 'Private project name'),
  customer: () => __(translations.editProjectGeneralSection.customer, 'Customer'),
  additionalInformations: () => __(translations.editProjectGeneralSection.additionalInformations, 'Additional informations ({{lang}})'),
  clientNotice: () => __(translations.editProjectGeneralSection.clientNotice, 'Client notice'),
  description: () => __(translations.editProjectGeneralSection.description, 'Description ({{lang}})'),
  surface: () => __(translations.editProjectGeneralSection.surface, 'Surface ({{lang}})'),
  location: () => __(translations.editProjectGeneralSection.location, 'Location ({{lang}})'),
  designation: () => __(translations.editProjectGeneralSection.designation, 'Designation'),
  projectStatus: () => __(translations.editProjectGeneralSection.projectStatus, 'Status'),
  projectType: () => __(translations.editProjectGeneralSection.projectType, 'Type'),
  exposedOnWebsite: () => __(translations.editProjectGeneralSection.exposedOnWebsite, 'Exposed on Website'),
  startsAt: () => __(translations.editProjectGeneralSection.startAt, 'Start at'),
  endsAt: () => __(translations.editProjectGeneralSection.endAt, 'End at'),

  informations: () => __(translations.editProjectGeneralSection.informations, 'Informations'),
  settings: () => __(translations.editProjectGeneralSection.settings, 'Settings'),

  // project types
  archi_residence: () => __(translations.editProjectGeneralSection.archi_residence, 'Residences'),
  archi_hotel_restaurant: () => __(translations.editProjectGeneralSection.archi_hotel_restaurant, 'Hotels & restaurants'),
  archi_retail: () => __(translations.editProjectGeneralSection.archi_retail, 'Retail'),
  scenography: () => __(translations.editProjectGeneralSection.scenography, 'Scenography'),
  design_collaborations: () => __(translations.editProjectGeneralSection.design_collaborations, 'Design'),
  petits_projets: () => __(translations.editProjectGeneralSection.petits_projets, 'Petits projets'),
  project_room: () => __(translations.editProjectGeneralSection.project_room, 'Project room'),
  institution: () => __(translations.editProjectGeneralSection.institution, 'Institution'),
  tiny_room: () => __(translations.editProjectGeneralSection.tiny_room, 'Tiny room'),
};
