import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ProductFamily} from 'types/product-family';
import {ResponseData} from 'types/response-data';

const updateProductFamilyWeb = api.injectEndpoints({
  endpoints: builder => ({
    updateProductFamilyWeb: builder.mutation<Response, UpdateProductFamilyRequest>(
      {
        query: params => ({
          method: 'PUT',
          url: `/product-family-webs/${params.id}`,
          body: {
            name: {
              fr: params.name.fr,
              en: params.name.en
            }
          },
        }),
      }
    ),
  }),
});

export type UpdateProductFamilyRequest = {
  id?: ObjectId;
  name: {
    fr: string,
    en: string
  }
};

type Response = ResponseData<ProductFamily>;

export const {useUpdateProductFamilyWebMutation} = updateProductFamilyWeb;
