import {MODAL} from './constants';
import commercialDocumentsModals from 'features/commercial/documents/constants/commercial-documents-modals';
import GlobalSearchModal from './global-search';
import ReincarnationAngel from './reincarnation-angel';
import SoulEater from './soul-eater';
import creditModals from 'features/acounting/credits/containers/modals';
import stockModals from 'features/stock/container/modals';
import ecotaxModals from 'features/acounting/ecotax/container/modal';
import overpaymentsModals from 'features/acounting/overpayments/containers/modals';
import invoiceModals from 'features/commercial/invoices/containers/modal';
import orderModals from 'features/commercial/orders/container/modal';
import pricingModals from 'features/commercial/pricings/container/modal';
import quoationModals from 'features/commercial/quotations/container/modal';
import directoryModals from 'features/directory/container/modal';
import AlertCloak from 'features/modal-notch/alert-cloak';
import productionModals from 'features/production/containers/modal';
import ColorArchiveModal from 'pages/dashboard/colors/color-delete-modal';
import ColorModal from 'pages/dashboard/colors/color-modal';
import MaterialModal from 'pages/dashboard/materials/material-modal';
import MaterialArchiveModal from 'pages/dashboard/materials/material-delete-modal';
import studioModals from 'features/studio/projects/constants/projects-modals';
import ColorWebModal from 'pages/dashboard/colors-web/color-modal';
import ColorWebArchiveModal from 'pages/dashboard/colors-web/color-delete-modal';

/**
 * All modals
 *
 */
export const Modals = {
  [MODAL.ALERT_CLOAK]: AlertCloak,

  [MODAL.GLOBAL_SEARCH]: GlobalSearchModal,

  [MODAL.REINCARNATION_ANGEL]: ReincarnationAngel,

  [MODAL.COLOR_MODAL]: ColorModal,
  [MODAL.COLOR_WEB_MODAL]: ColorWebModal,
  [MODAL.COLOR_ARCHIVE_MODAL]: ColorArchiveModal,
  [MODAL.COLOR_WEB_ARCHIVE_MODAL]: ColorWebArchiveModal,
  [MODAL.MATERIAL_MODAL]: MaterialModal,
  [MODAL.MATERIAL_ARCHIVE_MODAL]: MaterialArchiveModal,
  [MODAL.SOUL_EATER]: SoulEater,
  ...creditModals,

  ...ecotaxModals,
  ...overpaymentsModals,
  ...pricingModals,
  ...productionModals,
  ...directoryModals,
  ...quoationModals,
  ...orderModals,
  ...invoiceModals,
  ...commercialDocumentsModals,
  ...stockModals,
  ...studioModals,
};

/**
 * Modal sizing
 */
export const ModalClasses: Partial<Record<MODAL, string>> = {
  [MODAL.ADDRESS_FORM]: 'modal-dialog modal-dialog-centered',
  [MODAL.ALERT_CLOAK]: 'modal-dialog modal-dialog-centered',
  [MODAL.ATTACH_DIRECTORY]: 'modal-dialog modal-dialog-centered',
  [MODAL.DESTROY_DIRECTORY]: 'modal-dialog modal-dialog-centered',
  [MODAL.DETTACH_COMPANY]: 'modal-dialog modal-dialog-centered',
  [MODAL.FAMILY_FORM]: 'modal-dialog modal-dialog-centered',
  [MODAL.FAMILY_UPDATE_FORM]: 'modal-dialog modal-dialog-centered',
  [MODAL.GLOBAL_SEARCH]: 'modal-dialog modal-dialog-centered modal-lg ',
  [MODAL.PRICING_SITUATIONS]: 'modal-dialog modal-dialog-centered modal-xl',
  [MODAL.PRODUCT_ACTIONS_MODAL]: 'modal-dialog modal-dialog-centered',
  [MODAL.RESTORE_DIRECTORY]: 'modal-dialog modal-dialog-centered',
  [MODAL.SOUL_EATER]: 'modal-dialog modal-dialog-centered',
  [MODAL.TRANSFER_TO_SUPPLIER_ORDER_MODAL]:
    'modal-dialog modal-xl modal-w-90 modal-dialog-centered',
  [MODAL.STOCK_MOVEMENT_FORM]: 'modal-dialog modal-dialog-centered modal-xl',
  [MODAL.TRANSFER_STOCK_ORDER_MODAL]:
    'modal-dialog modal-xl modal-w-90 modal-dialog-centered',
  [MODAL.PREPERER_ORDER]:
    'modal-dialog modal-xl modal-w-90 modal-dialog-centered',

  [MODAL.UPDATE_CATALOG_FORM]: 'modal-dialog modal-dialog-centered',
  [MODAL.UPDATE_COSTS_FORM]: 'modal-dialog modal-dialog-centered',
  [MODAL.UPDATE_LIST_OF_CONTACT]: 'modal-dialog modal-dialog-centered',
  [MODAL.VARIANT_ACTIONS_MODAL]: 'modal-dialog modal-dialog-centered',
  [MODAL.VARIANT_ARCHIVE_ACTIONS_MODAL]: 'modal-dialog modal-dialog-centered',
  [MODAL.VARIANT_BLOCK_ACTIONS_MODAL]: 'modal-dialog modal-dialog-centered',
  [MODAL.VARIANT_DELETE_ACTIONS_MODAL]: 'modal-dialog modal-dialog-centered',
  [MODAL.VARIANT_TO_PRODUCT_ACTIONS_MODAL]:
    'modal-dialog modal-dialog-centered',
  [MODAL.ECOTAX_CREATE]: 'modal-dialog modal-dialog-centered',
  [MODAL.ECOTAX_UPDATE]: 'modal-dialog modal-dialog-centered',
  [MODAL.TRANSFER_ORDER_MODAL]:
    'modal-dialog modal-xl modal-w-90 modal-dialog-centered ',
  [MODAL.ORDER_SEND_LABEL_ACTIONS_MODAL]:
    'modal-dialog modal-xl modal-w-90 modal-dialog-centered ',
  [MODAL.INVOICE_SEND_EMAIL]: 'modal-dialog modal-dialog-centered ',
  [MODAL.TOGGLE_STATUS_OVERPAYEMENT]: 'modal-dialog modal-dialog-centered',
  [MODAL.UPDATE_CREDIT_STATUS]: 'modal-dialog modal-dialog-centered',
  [MODAL.TRANSFER_QUOTE]: 'modal-dialog modal-xl modal-dialog-centered',
  [MODAL.EXPORT_PATRIMOINE]: 'modal-dialog modal-dialog-centered',
  [MODAL.MATERIAL_ARCHIVE_MODAL]: 'modal-dialog modal-dialog-centered',
  [MODAL.MATERIAL_MODAL]: 'modal-dialog modal-dialog-centered',
  [MODAL.VARIANT_PREVIEW]: 'modal-dialog modal-lg modal-dialog-centered',
  [MODAL.STOCK_MOVEMENT_LIST]: 'modal-dialog modal-dialog-centered modal-xl',
  [MODAL.COMMERCIAL_PRODUCT_STOCK_MOVEMENT]:
    'modal-dialog modal-dialog-centered modal-xl',
  [MODAL.EDIT_ORDER_INFO]: 'modal-dialog modal-xl modal-w-80',
  [MODAL.DETAILS_STOCK_PRODUCT]: 'modal-dialog modal-xl modal-w-80',
  [MODAL.DETAILS_STOCK_IN_PRODUCT]: 'modal-dialog modal-xl modal-w-80',
  [MODAL.DETAILS_STOCK_IN_PREPARATION_MODAL]:
    'modal-dialog modal-xl modal-w-80',
  [MODAL.EDIT_STOCK_IN_PREPARATION_MODAL]: 'modal-dialog modal-xl modal-w-80',
  [MODAL.TRANSFER_STOCK_LOCATION_MODAL]: 'modal-dialog modal-lg modal-w-80',
  [MODAL.ORDER_SEND_EMAIL_ACTIONS_MODAL]:
    'modal-dialog modal-xl modal-w-90 modal-dialog-centered',
};
