import {selectProject} from 'features/studio/projects/store/show-project';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {
  useUpdateProjectMutation,
  UpdateProjectRequest,
} from 'features/studio/projects/store/update-project';
import {Project} from 'types/project';
import {
  EnumMapping,
  useEnumAsOption,
} from 'features/studio/projects/utils/use-enum-as-option';
import {ProjectTypeEnum} from 'features/studio/projects/constants/project-type';
import {ProjectStatusEnum} from 'features/studio/projects/constants/project-status';
import toast from 'store/ui/actions/toast';
import {ValidationErrors} from 'services/api/types';
import {differenceInCalendarMonths, parse} from 'date-fns';
import {useFilterTypesByPermission} from 'features/studio/projects/utils/use-filter-type-by-permission';
import {useCurrentSubject} from 'features/studio/projects/utils/use-current-subject';
import {Subject} from 'features/hylian-shield/types';

export const useGeneralForm = (): IGeneralForm => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const {id} = useParams();

  const {data} = useSelector(selectProject({id}));

  const project = data?.data as Project;

  const subject = useCurrentSubject(project.project_type);

  const [params, setParams] = useState<Params>(project as Params);

  const [updateProject, {isLoading, error, data: response}] =
    useUpdateProjectMutation();

  const serverError = parseValidationErrors(error);

  const handleSubmit = async () => {
    const result = await updateProject({
      ...params,
    } as UpdateProjectRequest);

    if ('data' in result) {
      const project = result?.data.data;
      dispatch(toast('success', t('common.changesCommited')));
      setParams(project);
    }
  };

  const handleChange = (key: keyof Params, value: any) => {
    if (key === 'project_type' || key === 'status') {
      value = value.id;
    }

    setParams(prev => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleDating = (
    key: keyof Pick<Params, 'starts_at' | 'duration_in_months' | 'ends_at'>,
    value: string
  ) => {
    if (key === 'duration_in_months') {
      if (!params.starts_at) return;

      const starts_at = new Date(params.starts_at as string);
      const ends_at = new Date(
        starts_at.setMonth(starts_at.getMonth() + Number(value))
      ).toDateString();

      setParams({...params, ends_at, [key]: value});
    } else if (key === 'ends_at') {
      if (!params.starts_at) return;

      const starts_at_date = parse(
        new Date(params.starts_at).toLocaleDateString(),
        'dd/MM/yyyy',
        new Date()
      );
      const ends_at_date = parse(
        new Date(value as string).toLocaleDateString(),
        'dd/MM/yyyy',
        new Date()
      );

      const duration = differenceInCalendarMonths(ends_at_date, starts_at_date);

      setParams({
        ...params,
        duration_in_months: duration.toString(),
        [key]: value.toString(),
      });
    } else {
      setParams({...params, [key]: value.toString()});
    }
  };

  useEffect(() => {
    if (!project) return;

    const starts_at_date = parse(
      new Date(project.starts_at).toLocaleDateString(),
      'dd/MM/yyyy',
      new Date()
    );
    const ends_at_date = parse(
      new Date(project.ends_at as string).toLocaleDateString(),
      'dd/MM/yyyy',
      new Date()
    );

    setParams({
      ...project,
      duration_in_months: differenceInCalendarMonths(
        ends_at_date,
        starts_at_date
      ),
    });
  }, [data]);

  const options = {
    types: useFilterTypesByPermission(
      useEnumAsOption(ProjectTypeEnum, 'editProjectGeneralSection')
    ),
    status: useEnumAsOption(ProjectStatusEnum, 'statusBadge'),
  };

  return {
    errorMessage: serverError.message,
    isLoading,
    params,
    statusText: response?.message,
    validationErrors: serverError.errors,
    options,
    subject,
    setParams,
    handleChange,
    handleDating,
    submit: handleSubmit,
  };
};

export interface IGeneralForm {
  isLoading: boolean;
  params: Params;
  errorMessage?: string;
  statusText?: string;
  validationErrors: ValidationErrors;
  subject: Subject;
  options: {
    types: EnumMapping<typeof ProjectTypeEnum>[];
    status: EnumMapping<typeof ProjectStatusEnum>[];
  };
  setParams: (arg: Params) => void;
  handleChange: (key: keyof Params, value: any) => void;
  handleDating: (
    key: keyof Pick<Params, 'starts_at' | 'duration_in_months' | 'ends_at'>,
    value: string
  ) => void;
  submit: () => void;
}

type Params = UpdateProjectRequest & {duration_in_months?: number | string};
