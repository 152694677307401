import {updateCachedVariantFromResource} from './get-product-variants';
import {updateCachedVariantDetailsFromResource} from './show-variant';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const updateVariantWebInfo = api.injectEndpoints({
  endpoints: builder => ({
    updateVariantWebInfo: builder.mutation<Response, VariantWebInfoRequest>({
      query: body => ({
        method: 'PUT',
        url: `/products/${body.id}/variants/${body.variant}/web`,
        body: {
          subtitle: body.subtitle,
          description: body.description,
          colors: body.colors,
          family_web_id: body.family_web_id,
          product_web_id: body.product_web_id,
          size_web_id: body.size_web_id,
          is_price_fixed: body.is_price_fixed,
          is_published: body?.is_published,
          use_size_web_as_name: body?.use_size_web_as_name
        },
      }),
      async onQueryStarted({}, {dispatch, queryFulfilled}) {
        try {
          const res = (await queryFulfilled).data.data;
          dispatch(updateCachedVariantFromResource(res));
          dispatch(updateCachedVariantDetailsFromResource(res));
        } catch {}
      },
    }),
  }),
});

export type VariantWebInfoRequest = {
  id?: ObjectId;
  variant?: ObjectId;
  subtitle: {
    fr: string;
    en: string;
  };
  description: {
    fr: string;
    en: string;
  };
  colors: ObjectId[];
  family_web_id: ObjectId;
  product_web_id: ObjectId;
  size_web_id: ObjectId;
  is_price_fixed: boolean;
  is_published: boolean;
  use_size_web_as_name: boolean;
};

type Response = ResponseData<ProductVariant>;

export const {useUpdateVariantWebInfoMutation} = updateVariantWebInfo;
