import {useEffect, useMemo, useState} from 'react';
import {ProjectAttachment} from 'types/attachements';
import {
  HandleAddAttachment,
  HandleChangeAttachment,
  HandleRemoveAttachment,
  HandleSubmit,
} from './types';
import {useGroupByType} from 'features/studio/projects/utils/use-regroup-by-type';
import {ProjectAttachmentTypesEnum} from 'features/studio/projects/constants/project-attachments';
import makeId from 'utils/make-id';
import {
  StoreProjectAttachmentsRequest,
  useStoreProjectAttachmentsMutation,
} from 'features/studio/projects/store/store-project-attachments';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectProject} from 'features/studio/projects/store/show-project';
import {ObjectId} from 'types/object-id';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useCurrentSubject} from 'features/studio/projects/utils/use-current-subject';
import {Subject} from 'features/hylian-shield/types';
import {now} from 'utils/now';

export const useAttachmentsForm = (): IAttachmentsForm => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [params, setParams] = useState({
    attachments: [],
    deleted_records: [],
  } as Params);

  const [storeAttachments, {isLoading: isSubmitting, error}] =
    useStoreProjectAttachmentsMutation();

  const serverError = parseValidationErrors(error);

  const {id} = useParams();

  const {data} = useSelector(selectProject({id}));

  const project = data?.data;

  const subject = useCurrentSubject(project?.project_type);

  useEffect(() => {
    if (!project) return;

    setParams({
      attachments: project.attachments ?? [],
    } as Params);
  }, []);

  const shapePayload = (params: Params) => {
    return {
      id,
      ...params,
    };
  };

  const handleSubmit: HandleSubmit = async () => {
    const result = await storeAttachments(
      shapePayload(params) as StoreProjectAttachmentsRequest
    );

    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
      setParams({
        deleted_records: [],
        attachments: result.data.data.attachments,
      });
    }
  };

  const handleChangeAttachment: HandleChangeAttachment = (
    identifier,
    key,
    value
  ) => {
    let updatedAttachments = [...params.attachments];

    // set file_id
    if (key === 'file' && value) {
      updatedAttachments[identifier].file_id = value.id;
    }

    // only one file must be primary image
    if (key === 'is_primary_image') {
      updatedAttachments = updatedAttachments.map((element, index) => {
        const newElement = {...element};
        if (index !== identifier) {
          newElement.is_primary_image = false;
        }

        return newElement;
      });
    }

    updatedAttachments[identifier] = {
      ...updatedAttachments[identifier],
      [key]: value,
    };

    setParams({...params, attachments: updatedAttachments});
  };

  const handleAddAttachment: HandleAddAttachment = type => {
    const newElement = {
      id: makeId(),
      date: now(),
      type,
    } as ProjectAttachment;

    if (type === ProjectAttachmentTypesEnum.HUB)
      newElement.order = params.attachments.length + 1;

    setParams({
      ...params,
      attachments: [...params.attachments, newElement],
    });
  };

  const handleRemoveAttachment: HandleRemoveAttachment = identifier => {
    const updatedAttachments = [...params.attachments];
    const deleted_records = [...(params.deleted_records ?? [])];

    if (
      project?.attachments.find(
        el => el.id === updatedAttachments[identifier].id
      )
    ) {
      deleted_records.push(updatedAttachments[identifier].id);

      if (
        updatedAttachments[identifier] &&
        updatedAttachments[identifier].type === ProjectAttachmentTypesEnum.PRESS
      ) {
        deleted_records.push(
          updatedAttachments[identifier].cover?.file?.id as ObjectId
        );
      }
    }

    updatedAttachments.splice(identifier, 1);
    setParams({...params, attachments: updatedAttachments, deleted_records});
  };

  const regroupedAttachments = useMemo(
    () =>
      useGroupByType(
        params.attachments,
        'attachments'
      ) as IRegroupedAttachments[],
    [params]
  );

  return {
    params,
    isSubmitting,
    regroupedAttachments,
    validationErrors: serverError.errors,
    subject,
    handleAddAttachment,
    handleChangeAttachment,
    handleRemoveAttachment,
    submit: handleSubmit,
  };
};

export interface IAttachmentsForm {
  params: Params;
  isSubmitting: boolean;
  regroupedAttachments: IRegroupedAttachments[];
  validationErrors: any;
  subject: Subject;
  handleAddAttachment: HandleAddAttachment;
  handleChangeAttachment: HandleChangeAttachment;
  handleRemoveAttachment: HandleRemoveAttachment;
  submit: HandleSubmit;
}

export interface IRegroupedAttachments {
  type: ProjectAttachmentTypesEnum;
  data: (ProjectAttachment & {identifier: number})[];
}

export type Params = {
  attachments: ProjectAttachment[];
  deleted_records: ObjectId[];
};
