import {useState} from 'react';
import messages from '../messages';
import {
  ArchiveColorRequest,
  useArchiveWebColorMutation,
} from '../store/endpoints/archive-color';
import {useListSlice} from 'features/table/slice';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {ObjectId} from 'types/object-id';

const RESOURCE_NAME = 'color-webs';
const FETCH_URL = RESOURCE_NAME;

export const useColorWebDeleteForm = (
  onDismiss: () => void,
  colorId: ObjectId
) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {actions} = useListSlice();

  const [params, setParams] = useState<Params>({
    id: colorId,
  });

  const [archiveColor, {isLoading, error}] = useArchiveWebColorMutation();

  const serverError = parseValidationErrors(error);

  const handleSubmit = async () => {
    const result = await archiveColor(params);
    if ('data' in result) {
      dispatch(toast('success', t(...messages.colorArchivedSuccess())));

      dispatch(actions.clearSelection(RESOURCE_NAME));
      dispatch(actions.loadData({url: FETCH_URL, name: RESOURCE_NAME}));

      onDismiss();
    }
  };

  return {
    setParams,
    params,
    isLoading,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
type Params = ArchiveColorRequest;
