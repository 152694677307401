import styled from 'styled-components';

// Styled Components
export const Container = styled.div`
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
`;

export const SelectorWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: -7px;
`;

export const LanguageSelector = styled.select`
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  padding: 3px;
  background-color: #f9f9f9;
  cursor: pointer;
`;
