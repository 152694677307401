import React from 'react';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ColumnDef} from '@tanstack/table-core';
import EmptyCell from 'components/empty-cell';
import ProjectActions from './project-actions';
import {Project, ProjectIndex} from 'types/project';
import FormattedDate from 'components/formatted-date';
import ProjectCover from './project-cover';
import Icon from '@mdi/react';
import {mdiCheck} from '@mdi/js';

const columns: ColumnDef<ProjectIndex>[] = [
  {
    header: () => (
      <span className="font-size-14 ">
        <Trans i18nKey="projectsPage.preview" />
      </span>
    ),
    accessorFn: row => <ProjectCover project={row} />,
    cell: r => r.getValue(),
    meta: {size: '60px'},

    enableSorting: false,
    id: 'preview',
  },
  {
    header: () => (
      <span className="font-size-14 ">
        <Trans i18nKey="projectsPage.name" />
      </span>
    ),
    accessorFn: row => (
      <Link to={`/dashboard/projects/${row.id}/general`}>
        <strong>{(row.name as string)?.toLocaleUpperCase()}</strong>
      </Link>
    ),
    cell: r => r.getValue(),
    meta: {size: '200px'},

    enableSorting: true,
    id: 'name',
  },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="projectsPage.customer" />
      </span>
    ),
    accessorFn: row => (
      <Link
        target="_BLANK"
        to={`/dashboard/directories/${row.client?.id}/general`}
      >
        <strong>{row.client?.display_name?.toLocaleUpperCase()}</strong>
      </Link>
    ),
    cell: r => r.getValue(),

    enableSorting: true,
    meta: {size: '200px'},

    id: 'client',
  },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="projectsPage.type" />
      </span>
    ),
    accessorFn: r => (
      <EmptyCell
        cell={
          <span className="text-uppercase">
            <Trans
              i18nKey={`projectsPage.${r.project_type
                ?.toString()
                .toLocaleLowerCase()}`}
            />
          </span>
        }
      />
    ),
    cell: r => r.getValue(),
    meta: {size: '200px'},

    enableSorting: true,
    id: 'type',
  },
  // {
  //   header: () => (
  //     <span className="font-size-14">
  //       <Trans i18nKey="projectsPage.category" />
  //     </span>
  //   ),
  //   accessorFn: r => <EmptyCell cell={
  //     <span className='text-uppercase'>
  //       <Trans i18nKey={`projectsPage.${r.project_category.toLocaleLowerCase()}`} />
  //     </span>
  //   } />,
  //   cell: r => r.getValue(),

  //   enableSorting: true,
  //   id: 'category',
  // },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="projectsPage.startsAt" />
      </span>
    ),
    accessorFn: r => (
      <EmptyCell cell={new Date(r.starts_at).toLocaleDateString()} />
    ),
    cell: r => r.getValue(),

    enableSorting: true,
    id: 'starts_at',
  },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="projectsPage.endsAt" />
      </span>
    ),
    accessorFn: r => {
      return (
        <span>
          <FormattedDate value={r.ends_at} format="date" />
        </span>
      );
    },
    cell: r => r.getValue(),
    enableSorting: true,
    id: 'ends_at',
  },
  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="projectsPage.online" />
      </span>
    ),
    accessorFn: row =>
      row.is_online ? (
        <Icon size={1} path={mdiCheck} className="text-success" />
      ) : null,
    cell: r => r.getValue(),
    meta: {
      hide: true,
      cellCostumClass: 'text-center',
      headerCostumClass: 'text-center',
    },
    enableSorting: true,
    id: 'is_online',
  },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="projectsPage.status" />
      </span>
    ),
    accessorFn: r => (
      <span className="text-uppercase fw-bold">
        {r.status ? (
          <Trans i18nKey={`statusBadge.${r.status?.toLocaleLowerCase()}`} />
        ) : (
          ''
        )}
      </span>
    ),
    cell: r => r.getValue(),

    enableSorting: true,
    id: 'status',
  },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.action" />
      </span>
    ),
    cell: r => r.getValue(),
    enableSorting: false,
    accessorFn: row => <ProjectActions project={row} />,
    id: 'action',
  },
];

export default columns;
