import ProductActionForm from './action-product';
import ProductActionDeleteForm from './action-product-delete';
import FamilyForm from './family-form';
import FamilyUpdateForm from './family-update-form';
import FamilyWebForm from './family-web-form';
import FamilyWebUpdateForm from './family-web-update-form';
import StockMovementForm from './stock-movement-form';
import StockMouvementModal from './stock-movements-list';
import TransferStockLocationModal from './transfer-stock-location-modal';
import UpdateCatalogForm from './update-catalog-form';
import UpdateCostsForm from './update-costs-form';
import VariantArchiveActionConfirm from './variant/archive';
import VariantBlockActionConfirm from './variant/block';
import VariantDeleteActionConfirm from './variant/delete';
import VariantDuplicatActionConfirm from './variant/duplicat';
import VariantTOCActionConfirm from './variant/to-old-collection';
import {MODAL} from 'features/modal-notch/constants';

export default {
  [MODAL.UPDATE_COSTS_FORM]: UpdateCostsForm,
  [MODAL.VARIANT_ARCHIVE_ACTIONS_MODAL]: VariantArchiveActionConfirm,
  [MODAL.VARIANT_BLOCK_ACTIONS_MODAL]: VariantBlockActionConfirm,
  [MODAL.VARIANT_DELETE_ACTIONS_MODAL]: VariantDeleteActionConfirm,
  [MODAL.VARIANT_DUPLICAT_ACTIONS_MODAL]: VariantDuplicatActionConfirm,
  [MODAL.VARIANT_TO_PRODUCT_ACTIONS_MODAL]: VariantTOCActionConfirm,
  [MODAL.FAMILY_FORM]: FamilyForm,
  [MODAL.FAMILY_UPDATE_FORM]: FamilyUpdateForm,
  [MODAL.FAMILY_WEB_FORM]: FamilyWebForm,
  [MODAL.FAMILY_WEB_UPDATE_FORM]: FamilyWebUpdateForm,
  [MODAL.PRODUCT_ACTIONS_MODAL]: ProductActionForm,
  [MODAL.PRODUCT_DELETE_ACTIONS_MODAL]: ProductActionDeleteForm,
  [MODAL.UPDATE_CATALOG_FORM]: UpdateCatalogForm,
  [MODAL.STOCK_MOVEMENT_FORM]: StockMovementForm,
  [MODAL.STOCK_MOVEMENT_LIST]: StockMouvementModal,
  [MODAL.TRANSFER_STOCK_LOCATION_MODAL]: TransferStockLocationModal,
};
