import React from 'react';
import {FC} from 'react';
import Can from 'features/hylian-shield/can';
import {tableSlice} from 'features/table/slice';
import {MODAL} from 'features/modal-notch/constants';
import {Trash2, Edit} from 'react-feather';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import {uiActions} from 'store/ui/ui-slice';
import { ProductWebFamilyIndex } from 'types/product-family';
import Button from 'components/button';

interface Props {
  family_web: ProductWebFamilyIndex;
}

const FamilyWebActions: FC<Props> = ({family_web}) => {

  const {id} = family_web;

  const dispatch = useDispatch();

  const {t} = useTranslation();

  const destroyFamilyWeb = () => {
    dispatch(
      tableSlice.actions.destroyResource({
        resourceId: id,
        resourceName: 'families-web',
      })
    );
  };

  return (
    <div className="d-flex  ">
      <Can action="administration" subject="products">
        <Button
          className="me-2"
          color="transparent"
          onClick={() => {
            dispatch(
              showModal(MODAL.FAMILY_WEB_UPDATE_FORM, {
                family: family_web,
              })
            );
          }}
        >
          <Edit size={20} />
        </Button>

        <Button
          tag="button"
          color="transparent"
          onClick={e =>
            dispatch(
              uiActions.confirmDelete({
                resourceId: id,
                name: 'families-web',
                onDestroy: destroyFamilyWeb,
              })
            )
          }
        >
          <Trash2 size={20} className="text-danger" />
        </Button>
      </Can>
    </div>
  );
};

export default FamilyWebActions;
