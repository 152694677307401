// App.jsx / App.tsx

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {TextEditorProps} from './types';
import React, {useMemo} from 'react';

export const TextEditor = ({
  value,
  label,
  errors,
  onChange,
  id,
}: TextEditorProps) => {
  const errorMessage = useMemo(() => {
    return Array.isArray(errors) ? errors.find(notNull => notNull) : errors;
  }, [errors]);

  return (
    <div>
      <label>{label}</label>
      <CKEditor
        id={id}
        editor={ClassicEditor}
        data={value ?? '<p></p>'}
        onChange={(event, editor) => onChange(editor.getData())}
      />
      {errorMessage ? (
        <div className="invalid-feedback d-block">{errorMessage} </div>
      ) : null}
    </div>
  );
};
