import React from 'react';
import ColorWebActions from './color-web-actions';
import {Trans} from 'react-i18next';
import styled from 'styled-components';
import {Color} from 'types/colors';
import { ColumnDef } from '@tanstack/table-core';

const columns: ColumnDef<Color>[] = [
  {
    header: r => <Trans i18nKey="colorsPage.name" />,
    accessorFn: row => row.name?.toLocaleUpperCase(),
    id: 'name',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="colorsPage.code" />,
    accessorFn: row => (
      <div >
        <ColorWebContainer color={row.code}></ColorWebContainer>
        <span className=" ms-3 ">{row.code}</span>
      </div>
    ),
    id: 'code',
    enableSorting: true,
    cell: r => r.getValue(),

  },
  {
    header: r => <Trans i18nKey="productPage.actions" />,
    accessorFn: row => <ColorWebActions color={row} />,
    id: 'action',
    enableSorting: false,
    cell: r => r.getValue(),

  },
];

export default columns;

const ColorWebContainer = styled.span`
  background-color: ${props => props.color};
  padding: 0.5rem;
  padding-right: 3rem;

`;
