import {useState} from 'react';
import loadData from 'features/table/slice/actions/load-data';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useUpdateProductFamilyWebMutation} from 'store/production/endpoints/update-family-web';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import { ProductWebFamilyIndex } from 'types/product-family';
import { useTranslation } from 'react-i18next';
import messages from './messages';

export const useFamilyWebForm = () => {
  const [submitFamily, {isLoading, error}] =
    useUpdateProductFamilyWebMutation();
  const {t} = useTranslation();
  const serverError = parseValidationErrors(error);
  const modalParams = useSelector(selectActiveModalParams);
  const family = modalParams?.family as ProductWebFamilyIndex;

  const [params, setParams] = useState({
    id: family?.id ?? 0,
    name: {
      fr: family?.name_translations?.fr ?? '',
      en: family?.name_translations?.en ?? ''
    }
  });
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submitFamily(params);
    if ('data' in result) {
      dispatch(toast('success', t(...messages.updatedToast())));
      dispatch(loadData({name: 'families-web', page: 1, url: 'product-family-webs'}));
      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
