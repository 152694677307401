import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  categories: () => __(translations.familyWebForm.category, 'Categories'),
  category: () => __(translations.familyWebForm.category, 'Category'),
  code: () => __(translations.familyWebForm.code, 'Code'),
  inspiration: () => __(translations.familyWebForm.inspiration, 'Inspiration'),
  name: () => __(translations.familyWebForm.name, 'Name'),
  okay: () => __(translations.familyWebForm.okay, 'Okay'),
  title: () => __(translations.familyWebForm.alertTitle, 'Family Web'),
  createdToast: () => __(translations.familyWebForm.createdToast, 'Family Web Created Successfully'),
};
