import StatusBadge from 'components/status-badge';
import messages from '../messages';
import React, {FC, Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {Row, Badge} from 'reactstrap';
import {Project} from 'types/project';
import {Link} from 'react-router-dom';
import {ExternalLink} from 'react-feather';
import i18next from 'i18next';

interface Props {
  project: Project;
}

const SummarySection: FC<Props> = props => {
  const {t} = useTranslation();

  const {project} = props;

  return (
    <div>
      <Fragment>
        <Row>
          <div>
            <div className="d-flex align-items-start mt-3">
              <div className="flex-grow-1">
                <div>
                  <div>
                    <h1 className="font-size-26 text-uppercase">
                      {project.name[i18next.language as 'fr' | 'en']}
                    </h1>
                    <div className="d-flex mb-2 gap-2">
                      <div className="d-flex gap-2">
                        <span>
                          <strong>{`${t(
                            ...messages.projectStatus()
                          )} : `}</strong>
                          <StatusBadge status={project.status} />
                        </span>
                      </div>
                      <div className="d-flex gap-2">
                        <span>
                          <strong>{`${t(
                            ...messages.projectType()
                          )} : `}</strong>
                          <Badge color="light">
                            {t(
                              `projectsPage.${project.project_type.toLocaleLowerCase()}`
                            )}
                          </Badge>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex mb-2 gap-2">
                      <div>
                        <span>
                          <strong>{t(...messages.client())} : </strong>
                          <Link
                            target="_BLANK"
                            to={`/dashboard/directories/${project.client?.id}/general`}
                          >
                            {project?.client?.display_name}{' '}
                            <ExternalLink size={14} />
                          </Link>
                        </span>
                      </div>
                      <div>
                        <span>
                          <strong>{`${t(
                            ...messages.directoryFamily()
                          )} : `}</strong>
                          {project.client?.directory_families
                            ?.map(fam => fam.name)
                            .join(', ') ?? '-'}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                      <span>
                        <strong>{`${t(...messages.startAt())} : `}</strong>
                        {new Date(project.starts_at).toLocaleDateString()}
                      </span>
                      <span>
                        <strong>{`${t(...messages.endAt())} : `}</strong>
                        {new Date(project.ends_at).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Fragment>
    </div>
  );
};

export default SummarySection;
