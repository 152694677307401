import React from 'react';
import TextInput from 'components/text-input';
import {ProductsFilters} from 'pages/dashboard/products/filters-section/types';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Row, Col, Button, Collapse} from 'reactstrap';
import {useLocalStorage} from 'usehooks-ts';
import {FilterMap} from '../slice/types';

interface FilterProps {
  filters: FilterMap<any>;
  onChangeFilters: (key: any, value: any) => void;
  onClearFilters?: () => void;
  children?: React.ReactNode;
  additionalActions?: any;
}

const SearchSection: FC<FilterProps> = props => {
  const {t} = useTranslation();
  const {
    filters,
    onChangeFilters,
    onClearFilters,
    children,
    additionalActions: AdditionalActions,
  } = props;
  const [v, tt] = useLocalStorage('is-advanced-search-open', false);

  return (
    <>
      <Row className="mb-4">
        <Col md={10}>
          <div className="justify-content-start align-items-center d-flex row-gap-2  flex-wrap ">
            <TextInput
              containerStyle="me-2"
              id="search-input"
              placeholder={t('listing.search')}
              value={filters['filter[keyword]'] as string}
              onChange={(x: string) => onChangeFilters('filter[keyword]', x)}
            />
            <Button
              onClick={onClearFilters}
              className="btn btn-sm  btn-light text-info me-2 "
            >
              <p className="my-1 mx-1 font-size-14  text-dark">
                {t('common.reset')}
              </p>
            </Button>

            <Button
              onClick={() => tt(!v)}
              active={v}
              color="light"
              className="me-2"
            >
              {t('common.advancedSearch')}
            </Button>
            <div>{!!AdditionalActions && <AdditionalActions />}</div>
          </div>
        </Col>
      </Row>

      {/* First row */}
      <Collapse isOpen={v}>{children}</Collapse>
    </>
  );
};

export default SearchSection;
