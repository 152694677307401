import React from 'react';
import TextInput from 'components/text-input';
import {LocalizedTextInput} from 'components/text-input/multi';

import {Col, Row} from 'reactstrap';
import ItemPicker from 'components/item-picker';
import DateInput from 'components/date-input';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import messages from '../messages';
import {IGeneralForm} from '../use-general-form';
import DirectoryPicker from 'features/studio/projects/components/contact-picker';
import {LocalizedTextEditor} from 'components/text-editor/multi-lang';

const Informations: FC<Props> = ({
  params,
  validationErrors,
  options,
  handleDating,
  handleChange,
}) => {
  const {t} = useTranslation();

  return (
    <div>
      <h4 className="pb-3">{t(...messages.informations())}</h4>
      <Row className="mb-3">
        <Col md={6} lg={6} xs={12} className="d-flex flex-column gap-3">
          <LocalizedTextInput
            label={lang =>
              t(...messages.projectName(), {
                lang,
              })
            }
            className="mb-1"
            value={lang => params.name[lang]}
            errors={[
              validationErrors[`name.fr`],
              validationErrors[`name.en`],
            ].flat()}
            onChange={(value, lang) => {
              handleChange('name', {
                ...params.name,
                [lang]: value,
              });
            }}
          />
          <TextInput
            label={t(...messages.privateProjectName())}
            rows={1}
            className="mb-1"
            value={params.internal_name}
            errors={validationErrors.internal_name}
            onChange={internal_name => {
              handleChange('internal_name', internal_name);
            }}
          />
        </Col>
        <Col md={6} lg={6} xs={12} className="d-flex flex-column gap-3">
          <DirectoryPicker
            label={t(...messages.customer())}
            value={params.client}
            className="mb-1"
            errors={validationErrors.directiory}
            onChange={directory => {
              handleChange('client', directory);
            }}
          />
          <ItemPicker
            label={t(...messages.projectType())}
            value={params.project_type}
            className="z-3"
            errors={validationErrors.project_type}
            options={options.types}
            getOptionLabel={(val: any) => val.name}
            onChange={project_type => {
              handleChange('project_type', project_type);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={6} xs={12}>
          <LocalizedTextInput
            label={lang =>
              t(...messages.surface(), {
                lang,
              })
            }
            className="mb-1"
            value={lang => params.surface[lang]}
            errors={[
              validationErrors[`surface.fr`],
              validationErrors[`surface.en`],
            ].flat()}
            onChange={(value, lang) => {
              handleChange('surface', {
                ...params.surface,
                [lang]: value,
              });
            }}
          />
        </Col>
        <Col md={6} lg={6} xs={12}>
          <LocalizedTextInput
            label={lang =>
              t(...messages.location(), {
                lang,
              })
            }
            className="mb-1"
            value={lang => params.location[lang]}
            errors={[
              validationErrors[`location.fr`],
              validationErrors[`location.en`],
            ].flat()}
            onChange={(value, lang) => {
              handleChange('location', {
                ...params.location,
                [lang]: value,
              });
            }}
          />
        </Col>
      </Row>

      <Row className="mb-3 mt-3">
        <Col md={12} lg={12} xs={12} className="d-flex flex-column gap-3">
          <LocalizedTextInput
            label={lang =>
              t(...messages.additionalInformations(), {
                lang,
              })
            }
            value={lang => params.additional_informations[lang]}
            errors={[
              validationErrors[`additional_informations.en`],
              validationErrors[`additional_informations.fr`],
            ].flat()}
            onChange={(value, lang) =>
              handleChange('additional_informations', {
                ...params.additional_informations,
                [lang]: value,
              })
            }
          />
        </Col>
      </Row>

      <TextInput
        label={t(...messages.clientNotice())}
        className="mb-3"
        type="textarea"
        rows={3}
        data-testid="product-name-input"
        value={params.client_notice}
        errors={validationErrors.client_notice}
        onChange={client_notice => handleChange('client_notice', client_notice)}
      />

      <Row className="mb-3">
        <Col md={6} lg={6} xs={12} className="d-flex flex-column gap-3">
          <ItemPicker
            label={t(...messages.projectStatus())}
            value={params.status}
            options={options.status}
            className="mb-1"
            errors={validationErrors['status']}
            getOptionLabel={(val: any) => val.name}
            onChange={status => {
              handleChange('status', status);
            }}
          />
        </Col>
        <Col md={6} lg={6} xs={12} className="d-flex flex-column gap-3">
          <Row>
            <Col lg={5}>
              <div>
                <DateInput
                  label={t(...messages.startsAt())}
                  errors={validationErrors.starts_at}
                  value={params.starts_at?.toString()}
                  onChange={starts_at => {
                    handleDating('starts_at', starts_at as string);
                  }}
                />
              </div>
            </Col>
            <Col>
              <TextInput
                label="Durée"
                type="number"
                value={params.duration_in_months}
                disabled={!params.starts_at}
                onChange={duration_in_months =>
                  handleDating('duration_in_months', duration_in_months)
                }
              />
            </Col>
            <Col lg={5}>
              <div>
                <DateInput
                  label={t(...messages.endsAt())}
                  disabled={!params.starts_at}
                  errors={validationErrors.ends_at}
                  value={params.ends_at}
                  onChange={ends_at => {
                    handleDating('ends_at', ends_at as string);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <TextInput
        label={t(...messages.designation())}
        type="textarea"
        rows={3}
        className="mb-5"
        value={params.designation}
        errors={validationErrors.designation}
        onChange={designation => {
          handleChange('designation', designation);
        }}
      />
      <Row>
        <LocalizedTextEditor
          id={lang => `description_${lang}`}
          label={lang =>
            t(...messages.description(), {
              lang,
            })
          }
          errors={[
            validationErrors[`description.fr`],
            validationErrors[`description.en`],
          ].flat()}
          value={lng => (params.description ? params.description[lng] : '')}
          onChange={(value, lang) => {
            handleChange('description', {
              ...params.description,
              [lang]: value,
            });
          }}
        />
      </Row>
    </div>
  );
};

type Props = IGeneralForm;

export default Informations;
